@import "./config.scss";
@import "form.scss"; 

body,html{
    overflow-x: hidden;
}
body{
    font-family: map-get($fonts,one);
}
h1,h2,h3,h4{
    font-family: map-get($fonts,one);
}
ul{
    margin: 0;
}
footer h3{
    font-family: map-get($fonts,three) !important;
    font-weight: 700 !important;
}
.slide-navigate-item{
    background: #FFF;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    -webkit-box-shadow: 0 0 8px 0 #B8B8B8;
    box-shadow: 0 0 8px 0 #B8B8B8;
    border-radius: 100%;
    position: absolute;
    top: 32%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide-navigate-item.prev{
    left: -20px;
}
.slide-navigate-item.next{
    right: -20px;
}
.slide-navigate-item i{
    color: map-get($colors,one);
}
.slide-navigate-item.template-2{
    background: transparent;
    box-shadow: none;
}
.slide-navigate-item.template-2 i{
    color: #FFF;
    font-size: 70px;
}
.box-sombra{
    background: #FFF;
    box-shadow: 5px 5px 20px 0 #d4d4d4;
    padding: 20px;
}
.slide-navigate-item.template-2 .content{
    background: #FFF;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slide-navigate-item.template-2 .content i{
    color: map-get($colors,one);
    font-size: 25px;
}
.slide-navigate-item.template-2.prev{
    left: 10px;
}
.slide-navigate-item.template-2.next{
    right: 10px;
}
.no-focus-outline{
    overflow: hidden !important;   
}
.article-strong strong{
    font-weight: 900;
    color: map-get($colors,thirteen);
}
.grecaptcha-badge{
    display: none !important;
}

@media(max-width: 767px){

    .slick-padding-mobile .slick-list{
        padding: 10px 20px 10px 10px !important;
    }
    footer .module-title{
        margin: 30px 0 0 0 !important;
    }
    .module .title h2,
    .module-title h2{
        font-size: 30px !important;
    }
    .module .title h3,
    .module-title h3{
        font-size: 18px !important;
    }
    .footer-partial .module,.footer-partial .modulo{  
        margin-bottom: 30px !important; 
        display: table;
        width: 100%;
    }

} 

