
$colors: (
    one: #1575b9,
    two: #ececec,
    three: #575756,
    four: #f6f6f6,
    five: #0b2241,
    six: #1d4980,
    seven: #464646,
    eight: #f7f7f7,
    nine: #208390,
    ten: #f9f9f9,
    eleven: #146f7c,
    twelve: #32a0ae,
    thirteen: #aee27c
);

.btn-one{
    background: map-get($colors,one);
    border-radius: 5px;
    padding: 10px 20px;
    color: #FFF;
    border: 0;
    cursor: pointer;
}
.btn-two{
    background: #fff;
    color: map-get($colors,one);
    border-radius: 5px;
    padding: 10px 20px;
    border: 0;
    cursor: pointer;
}
.btn-icon{
    display: flex;
    align-items: center;
}
.btn-icon i,.btn-icon img{
    font-size: 18px;
    margin: 0 10px 0 0;
}